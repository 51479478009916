.user-img-col > img{
height: 60px;
width: 60px;
border-radius: 50%;
object-fit: cover;
}
.user-img-col{
  display: flex;
  align-items: center;
}
.agent-data > div{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title-text{
  font-size: 14px;
  font-weight: 500;
  word-break: break-all;
}
.status-box{
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  color:white;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;

}
.pin-col{
  color:#AE2A82;
  font-style: italic;
  font-weight: 500;
}
.available{
  background-color: #92Ca50;
}
.offline{
  background-color: red;
}
.busy{
  background-color: #AE2A82;
}
.agent-row{
  justify-content: center;
}
.avatar {
  width:128px;
  margin: 10px;
  border:5px solid rgb(237, 235, 235);
  border-radius: 500px;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
}
.line-row{
  display: flex;
  justify-content: center;
}
.line {
  border: 1px solid rgb(237, 235, 235);
  width: 80% !important;
}
@media only screen and (max-width: 576px) {
 .max-width{
 
 }
}